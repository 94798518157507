.gm-style-iw-chr {
    height: 0px !important;
    min-height: 0px !important;
    padding: 0 !important;
}

.gm-style-iw {
    width: 200px !important;
    max-width: 200px !important;
    padding: 0 !important;
}

/* .gm-style-iw button {
    display:none !important;
} */

.gm-style-iw-d {
    overflow: visible !important;
}

.gm-style-iw-d > div {
    width: 200px !important;
    max-width: 200px !important;
    padding: 0 !important;
}

sx={{ '&.MuiButton-root:hover':{bgcolor: 'transparent'} }}